import {
	Component,
	Input
} from '@angular/core';

import { PORTAL_MEDIA_MY_SUBSCRIPTIONS_STATE, PORTAL_VIDEO_PLAYBACK_STATE } from 'rev-shared/bootstrap/AppStateNames';

import { INotification } from './Notification.Contract';
import './notification.less';

@Component({
	selector: 'vb-notification',

	templateUrl: './Notification.Component.html'
})
export class NotificationComponent {
	@Input() public notification: INotification;

	public readonly PORTAL_MEDIA_MY_SUBSCRIPTIONS_STATE = PORTAL_MEDIA_MY_SUBSCRIPTIONS_STATE;
	public readonly PORTAL_VIDEO_PLAYBACK_STATE = PORTAL_VIDEO_PLAYBACK_STATE;
}
