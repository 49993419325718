<div class="list-group">
	<a *ngIf="!readonlyUserProfile" role="menuitem" class="dropdown-item btn-icon-left logout-tray" [uiSref]="'portal.userProfile'">
		<span class="vb-icon vb-icon-center vb-icon-person">
		</span>
		{{ 'UserProfile_MyProfile' | translate }}
	</a>
	<a *ngIf="showManageSubscriptions" role="menuitem" class="dropdown-item btn-icon-left logout-tray" [uiSref]="'portal.manageSubscriptions'">
		<span class="vb-icon vb-icon-center vb-icon-double-cog">
		</span>
		{{ 'ManageSubscriptions' | translate }}
	</a>
	<a role="menuitem" class="dropdown-item btn-icon-left logout-tray" [uiSref]="'logout'">
		<span class="vb-icon vb-icon-center vb-icon-exit">
		</span>
		{{ 'Logout' | translate }}
	</a>
</div>
