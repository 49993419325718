import { Component, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import { StateService } from '@uirouter/angular';
import { Subscription } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';

import { BrandingSettings } from 'rev-portal/branding/BrandingSettings.Contract';
import { ThemeService } from 'rev-portal/branding/Theme.Service';
import { SearchService } from 'rev-portal/search/Search.Service';
import { MediaStateService, MediaViewMode } from 'rev-portal/media/MediaState.Service';

import { ButtonType } from 'rev-shared/ui/inlineEdit/TypeaheadButtonType';
import { IRules } from 'rev-shared/ui/css/CssRules.Contract';
import { ITypeaheadCfg } from 'rev-shared/ui/inlineEdit/ITypeaheadCfg';
import { SourceType } from 'rev-shared/ui/inlineEdit/TypeaheadSourceType';
import { UserContextService } from 'rev-shared/security/UserContext.Service';
import { isString } from 'rev-shared/util';
import { PORTAL_MEDIA_SEARCH_STATE } from 'rev-shared/bootstrap/AppStateNames';

import { SearchHeaderService } from './SearchHeader.Service';

import './search-header.less';

const TypeaheadResultSize = 10;

@Component({
	selector: 'search-header',
	templateUrl: './SearchHeader.Component.html'
})
export class SearchHeaderComponent implements OnInit, OnDestroy {
	@Input() public showSearchText: boolean;
	@Input() public buttonCssClasses: string;
	@Input() public headerType: string;

	private readonly TypeaheadMinLength = 1;
	private loadTypeaheadVideos = query => this.queryVideos(query);
	private themeServiceSub: Subscription;

	public themeStyleOverrides: IRules;
	public searchTypeaheadCfg: ITypeaheadCfg;

	constructor(
		private $state: StateService,
		private MediaStateService: MediaStateService,
		private SearchService: SearchService,
		private UserContext: UserContextService,
		private translateService: TranslateService,
		public ThemeService: ThemeService,
		public SearchHeaderService: SearchHeaderService
	) { }

	public ngOnInit(): void {
		this.setSearchTypeaheadCfg();
		this.themeServiceSub = this.ThemeService.brandingSettings$.subscribe(brandingSettings => {
			this.applyCssRules(brandingSettings);
			this.updateSearchTypeaheadCfgHeaderType(brandingSettings);
		});
	}

	public ngOnDestroy(): void {
		this.themeServiceSub?.unsubscribe();
	}

	private setSearchTypeaheadCfg(): void {
		this.searchTypeaheadCfg = {
			label: this.translateService.instant('Search'),
			btnCfg: {
				type: ButtonType.Search,
				label: this.showSearchText ? this.translateService.instant('Search') : undefined,
				cssClass: this.getButtonCssClasses(),
			},
			searchDropdownCfg: {
				themedSearchDropdownClass: this.getThemedDropdownCssClasses()
			},
			getEnteredValueWhenNoSuggestion: true,
			inputClass: 'dropdown-item',
			loadResults: this.loadTypeaheadVideos,
			minLength: this.TypeaheadMinLength,
			submitOnSelect: true,
			source: SourceType.HeaderSearch,
			headerType: this.ThemeService.brandingSettings.headerSettings.brandingHeaderType || undefined,
			searchField: 'title'
		};
	}

	private updateSearchTypeaheadCfgHeaderType(brandingSettings: BrandingSettings): void {
		this.searchTypeaheadCfg.headerType = brandingSettings.headerSettings.brandingHeaderType;
	}

	private getButtonCssClasses(): string {
		return this.headerType === 'Classic' ? 'themedSearchButton' : `btn main-nav-btn theme-accent-btn ${this.buttonCssClasses}`;
	}

	private getThemedDropdownCssClasses(): string {
		return this.headerType === 'Classic' ? 'theme-header' : '';
	}

	private applyCssRules(brandingSettings: BrandingSettings): void {
		const headerFontColor = brandingSettings.headerSettings.fontColor;
		this.themeStyleOverrides = {
			'search-header vb-ui-typeahead-angular input[vbUiTextInput]::-moz-placeholder': {
				'color': `${headerFontColor}`
			},
			'search-header vb-ui-typeahead-angular input[vbUiTextInput]:-ms-input-placeholder': {
				'color': `${headerFontColor} !important`
			},
			'search-header vb-ui-typeahead-angular input[vbUiTextInput]::-webkit-input-placeholder': {
				'color': `${headerFontColor}`
			}
		};
	}

	public submitMediaSearch(value: { title: string; }): void {
		if (!value) {
			return;
		}
		const searchVal = isString(value) ? value : value.title;
		this.MediaStateService.setViewMode(MediaViewMode.TABLE);
		this.$state.go(PORTAL_MEDIA_SEARCH_STATE, { q: searchVal }, { inherit: false, reload: true });
	}

	public queryVideos(query: string): Promise<any> {
		return this.SearchService.getVideoSuggestions({
			query: query.toLowerCase(),
			accountId: this.UserContext.getAccount().id,
			count: TypeaheadResultSize,
			noScroll: true
		})
			.then(({ videos }) => videos);
	}

}
