import { Component } from '@angular/core';

import { MediaMenuService } from './MediaMenu.Service';

import { PORTAL_MEDIA_ALL_STATE, PORTAL_MEDIA_UPLOADS_STATE, PORTAL_MEDIA_BROWSE_STATE, PORTAL_MEDIA_EXPIRATIONS_STATE, PORTAL_MEDIA_MY_SUBSCRIPTIONS_STATE, MY_PLAYLIST_STATE } from 'rev-shared/bootstrap/AppStateNames';

@Component({
	selector: 'my-videos-menu-items',
	templateUrl: './MyVideosMenuItems.Component.html'
})
export class MyVideosMenuItemsComponent {

	constructor(
		public MediaMenuService: MediaMenuService
	) { }

	public readonly appStates = {
		myPlaylist: MY_PLAYLIST_STATE,
		mediaAll: PORTAL_MEDIA_ALL_STATE,
		mediaUploads: PORTAL_MEDIA_UPLOADS_STATE,
		mediaBrowse: PORTAL_MEDIA_BROWSE_STATE,
		mediaExpirations: PORTAL_MEDIA_EXPIRATIONS_STATE,
		mediaMySubscriptions: PORTAL_MEDIA_MY_SUBSCRIPTIONS_STATE
	};

}
