import { IVbNg2StateDeclaration } from 'rev-shared/ts-utils/IVbNg2StateDeclaration';
import { PORTAL_USER_NOTIFICATION } from 'rev-shared/bootstrap/AppStateNames';

import { UserNotificationsComponent } from './UserNotifications.Component';

export const states: IVbNg2StateDeclaration[] = [{
	name: PORTAL_USER_NOTIFICATION,
	url: '/user-notifications',
	component: UserNotificationsComponent,
}];
