import { Component, Input, OnChanges } from '@angular/core';

import { orderBy } from 'rev-shared/util/SortUtil';
import { ITeam } from 'rev-shared/team/Team.Contract';
import { PORTAL_MEDIA_STATE } from 'rev-shared/bootstrap/AppStateNames';


@Component({
	selector: 'my-teams-menu-items',
	templateUrl: './MyTeamsMenuItems.Component.html'
})
export class MyTeamsMenuItemsComponent implements OnChanges {
	@Input() private teamData: ITeam[];
	@Input() private maxVisibleTeams: number;

	public sortedTeams: ITeam[];
	public readonly PORTAL_MEDIA_STATE = PORTAL_MEDIA_STATE;

	public ngOnChanges(): void {
		this.sortedTeams = orderBy(this.teamData, t => t.name).slice(0, this.maxVisibleTeams);
	}

}
