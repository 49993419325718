import { WritableSignal } from '@angular/core';

export enum GenerateMetadataOption {
	TITLE = 'title',
	DESCRIPTION = 'description',
	TAGS = 'tags',
	CHAPTERS = 'chapters'
}

export enum GenerateMetadataStatus {
	ENABLED = 'enabled',
	PROCESSING = 'processing',
	HIDDEN = 'hidden'
}

export enum MetadataGenerationJobStatus {
	INPROGRESS = 'InProgress',
	SUCCESS = 'Success',
	FAILED = 'Failed'
}

export interface IChapterMetadata {
	extension: string;
	imageId: string;
	isUploadedImage: boolean;
	time: string;
	title: string;
}

export type IGenerateMetadataOptionSignalMap = {
	[key in GenerateMetadataOption]: WritableSignal<GenerateMetadataStatus>
};

export interface VideoMetadataGenerationJobSubmittedEvent {
	generationStatus: MetadataGenerationJobStatus;
	metadataGenerationFields: GenerateMetadataOption[];
}

export interface VideoMetadataGenerationJobFinishedEvent {
	generationStatus: MetadataGenerationJobStatus;
	metadataGenerationFields: GenerateMetadataOption[];
	title: string;
	description: string;
	tags: string[];
	videoChapters: IChapterMetadata[];
}

export interface VideoMetadataGenerationJobFailedEvent {
	videoId: string;
	userId: string;
	videoOwnerId: string;
	title: string;
	languageId: string;
	type: string;
	metadataGenerationFields: GenerateMetadataOption[];
}

