import {
	Component,
	ElementRef,
	OnInit,
	ViewChild
} from '@angular/core';
import { map, Observable, tap } from 'rxjs';

import { MainHeaderPositionService } from './navigation/mainHeader/MainHeaderPosition.Service';

import styles from './Portal.module.less';

@Component({
	selector: 'portal',
	host: {
		'[class]': 'styles.portal'
	},
	template: `
		<div id="global-nav"
			class="global-nav-wrap fixed-top theme-accent-txt">
			<main-header
				[mainContent]="mainContentEl"></main-header>
		</div>
		<div
			#mainContent
			class="height-full"
			ui-view
			[ngStyle]="headerStyle$ | async">
		</div>
		<branding-preview-banner></branding-preview-banner>
	`
})
export class PortalComponent implements OnInit {
	public headerStyle$: Observable<any>;

	public readonly styles = styles;

	@ViewChild('mainContent', { read: ElementRef, static: true })
	public mainContentEl: ElementRef;

	constructor(
		private readonly elementRef: ElementRef,
		private readonly MainHeaderPosition: MainHeaderPositionService
	) {}

	public ngOnInit(): void {
		const paddingTop = 1;
		this.headerStyle$ = this.MainHeaderPosition.height$
			.pipe(
				tap(height => this.setMainHeaderHeight(height)),
				map(height => ({
					'padding-top': paddingTop + 'px',
					'margin-top': (height ? height - paddingTop : 0) + 'px'
				})),
			);
	}

	private setMainHeaderHeight(height: number): void {
		this.elementRef.nativeElement.style.setProperty('--portal-main-header-height', height + 'px');
	}
}
