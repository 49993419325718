import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StateService } from '@uirouter/angular';
import { TranslateService } from '@ngx-translate/core';

import { EditWebcastModel } from 'rev-portal/scheduledEvents/editWebcast/EditWebcastModel';
import { EditWebcastService } from 'rev-portal/scheduledEvents/editWebcast/EditWebcast.Service';
import { EventAccessControl } from 'rev-portal/scheduledEvents/EventAccessControl';
import { WEBCAST_VIEW_STATE, WEBCAST_LANDING_STATE } from 'rev-portal/scheduledEvents/webcast/Constants';
import { WebcastVideoSource } from 'rev-portal/scheduledEvents/webcast/WebcastVideoSource';
import { formatMediumDateTime } from 'rev-shared/date/DateFormatters';
import { DateUtil } from 'rev-shared/date/DateUtil';
import { IMediaFeatures } from 'rev-shared/media/IMediaFeatures';
import { FieldType } from 'rev-shared/metadata/FieldType';
import { IRegistrationField } from 'rev-shared/metadata/Metadata.Contract';
import { MetadataService } from 'rev-shared/metadata/Metadata.Service';
import { WebcastTemplatesService } from 'rev-shared/webcast/WebcastTemplates.Service';
import { AccountLicenseService } from 'rev-shared/security/AccountLicense.Service';
import { SecurityContextService } from 'rev-shared/security/SecurityContext.Service';
import { UserContextService } from 'rev-shared/security/UserContext.Service';
import { ITemplateInfo } from 'rev-shared/webcast/WebcastTemplates.Contract';
import { RecordingPolicy } from 'rev-shared/media/RecordingPolicy';

import styles from './GoLive.module.less';

@Component({
	selector: 'go-live-form',
	templateUrl: './GoLiveForm.Component.html'
})

export class GoLiveFormComponent implements OnInit {
	@Input() public mediaFeatures: IMediaFeatures;
	@Output() private close: EventEmitter<any> = new EventEmitter<any>();

	private readonly GoLiveFormDefaults: any = {
		videoSource: WebcastVideoSource.WEBRTC_SINGLE_PRESENTER,
		presenterId: this.UserContext.getUser().id,
		presenter: { id: this.UserContext.getUser().id },
		recordingUploaderUserId: this.UserContext.getUser().id
	};

	private registrationFields: IRegistrationField[];

	public readonly styles = styles;
	public readonly EventAccessControl = EventAccessControl;
	public readonly FieldType = FieldType;
	public readonly hours = Array(10).fill(0).map((x, i) => i + 1);
	public readonly templateFilters = (template => template.videoSource === WebcastVideoSource.WEBRTC_SINGLE_PRESENTER);

	public accountId: string = this.UserContext.getAccount().id;
	public hasSetEventPublicRights: boolean;
	public insufficientRevIQCredits: boolean;
	public insufficientViewingHours: boolean;
	public useTemplate: boolean;
	public webcast: EditWebcastModel;

	constructor(
		private readonly AccountLicense: AccountLicenseService,
		private readonly EditWebcastService: EditWebcastService,
		private SecurityContext: SecurityContextService,
		private readonly MetadataService: MetadataService,
		private readonly UserContext: UserContextService,
		private readonly Translate: TranslateService,
		private readonly WebcastTemplatesService: WebcastTemplatesService,
		private readonly $state: StateService
	) {
		this.hasSetEventPublicRights = SecurityContext.checkAuthorization('events.setEventPublic');
	}

	public get hoursAndCreditsInvalid(): boolean {
		const namedOrActiveLicenseContentRestricted = this.AccountLicense.isLicenseTypeNamedOrActive && this.mediaFeatures.recordingPolicy !== RecordingPolicy.DISABLE;

		return this.insufficientRevIQCredits || (this.insufficientViewingHours && (this.AccountLicense.isLicenseTypeByHours || namedOrActiveLicenseContentRestricted));
	}

	public get publicListingVisible(): boolean {
		return this.hasSetEventPublicRights && this.mediaFeatures.enablePublicWebcastAccess && this.AccountLicense.viewingHoursValid;
	}

	public ngOnInit(): void {
		this.initialize();
		this.AccountLicense.updateLicenseHoursValid().then(() => this.checkLicenseHours());
		this.MetadataService.loadAccountRegistrationFields(this.accountId).then(() => {
			this.registrationFields = this.MetadataService.getAccountRegistrationFields(this.accountId).filter(x => x.includeInAllWebcasts);
		});
	}

	public initialize(): void {
		const webcast = this.EditWebcastService.createNewWebcast({
			title: this.Translate.instant('Event_SelfProduced_UserLiveAtTime', {
				'0': this.UserContext.getUser().fullName,
				'1': formatMediumDateTime(new Date())
			}),
			description: '',
			videoSource: WebcastVideoSource.WEBRTC_SINGLE_PRESENTER,
			webcastBrandingSettings: {},
			estimatedDurationHours: 1
		});

		this.EditWebcastService.getDefaultTemplate()
			.then((template: ITemplateInfo) => this.configureAndApplyTemplate(template, webcast))
			.catch(e => console.error('Error initializing go live', e));
	}

	public toggleTemplate(): void {
		this.useTemplate = !this.useTemplate;
	}

	public applyTemplate(id: string): Promise<void> {
		return this.WebcastTemplatesService.getTemplate(id).then(template => {
			return this.configureAndApplyTemplate(template, this.webcast).then(() => {
				this.useTemplate = false;
			});
		});
	}

	public moreSettings(): void {
		this.setStartAndEndTimes();
		this.$state.go(WEBCAST_LANDING_STATE, { webcastId: '', editWebcast: this.webcast }, { reload: true, inherit: false });
		this.close.emit();
	}

	public onAccessControlChange(): void {
		this.applyRegistrationFields();
	}

	public submit(): void {
		this.setStartAndEndTimes();

		this.EditWebcastService.saveWebcast(this.webcast)
			.then(() => {
				//Needed because close clears out the webcast object
				const id = this.webcast.id;
				this.close.emit();
				setTimeout(() => this.$state.go(WEBCAST_VIEW_STATE, { webcastId: id }, { reload: true, inherit: false }), 2000);
			});
	}

	private setStartAndEndTimes (): void {
		this.webcast.startDate = new Date();
		this.webcast.endDate = DateUtil.addHours(this.webcast.startDate, this.webcast.estimatedDurationHours);
	}

	private configureAndApplyTemplate(template: ITemplateInfo, webcast: EditWebcastModel): Promise<void> {
		return this.EditWebcastService.applyTemplateToWebcast(
			this.applyGoLiveDefaults(template),
			webcast)
			.then((webcast: EditWebcastModel) => {
				this.webcast = webcast;
				this.checkLicenseHours();
				return this.applyRegistrationFields();
			});
	}

	private applyGoLiveDefaults(template: ITemplateInfo): ITemplateInfo {
		if (template.metadata) {
			Object.assign(template.metadata, this.GoLiveFormDefaults);
			template.metadata.eventAdminIds.push(this.UserContext.getUser().id);
		}
		return template;
	}

	private applyRegistrationFields(): void {
		if (this.webcast.accessControl === EventAccessControl.Public) {
			Object.assign(this.webcast.registrationFields, this.registrationFields);
		}
	}

	private checkLicenseHours(): void {
		this.insufficientViewingHours = !this.AccountLicense.viewingHoursValid;
		this.insufficientRevIQCredits = !this.AccountLicense.aiCreditsValid && this.webcast?.liveSubtitles?.isLiveSubtitlesEnabled;
	}

	public trackByIndex(index: number): number {
		return index;
	}
}
