import { Component } from '@angular/core';

import { IRules } from 'rev-shared/ui/css/CssRules.Contract';

import { MainHeaderComponent } from './MainHeader.Component';

@Component({
	selector: 'classic-header',
	templateUrl: './ClassicHeader.Component.html'
})
export class ClassicHeaderComponent extends MainHeaderComponent {

	public themeStyleOverrides: IRules;

	public ngOnDestroy(): void {
		this.themeServiceSub?.unsubscribe();
	}
}
