<ng-template [vbCssRules]="themeStyleOverrides">
</ng-template>
<section class="theme-accent-border">
	<div class="media-videos theme-accent-bg">
		<h1 class="media-box-header theme-accent-txt">{{ 'Media' | translate }}</h1>
		<my-videos-menu-items>
		</my-videos-menu-items>
	</div>

	<div class="media-teams theme-header-bg" flex="fill">
		<div class="teams-section-header theme-header-font-border-color">
			<div class="teams-header-text-section">
				<div class="media-box-header theme-header theme-header-txt text-hover-none">
					{{ 'Teams_Title' | translate }}
				</div>
				<div class="spacer"></div>
				<div *ngIf="TeamService.isCreateTeamAccess" class="create-new-team-section theme-header-font-border-color">
					<a class="theme-header theme-header-text teams-section-header-link" [uiSref]="PORTAL_TEAM_EDIT_STATE">
						{{ 'AddNew' | translate }}
					</a>
				</div>
				<div class="all-teams-section" *ngIf="MediaMenuService.hasTeams">
					<a class="theme-header theme-header-text teams-section-header-link" [uiSref]="PORTAL_TEAM_STATE">
						{{ 'ViewAll' | translate }}
					</a>
				</div>
			</div>
		</div>
		<vb-loading-spinner [block]="true" [size]="'large'" [hidden]="!MediaMenuService.isFetching">
		</vb-loading-spinner>

		<div class="no-teams" [ngClass]="{'animated fadeIn': MediaMenuService.isFirstFetchComplete}" *ngIf="!MediaMenuService.hasTeams">
			<h3 class="theme-header-txt">{{ 'Teams_NotAvailable' | translate }}</h3>
		</div>

		<my-teams-menu-items *ngIf="MediaMenuService.hasTeams" [teamData]="MediaMenuService.TeamListingsService.teamData" [maxVisibleTeams]="MediaMenuService.maxVisibleTeams">
		</my-teams-menu-items>
	</div>
</section>
