import { Component, Input, OnInit } from '@angular/core';
import { StateService } from '@uirouter/angular';

import { BrandingHeaderType } from 'rev-portal/branding/BrandingHeaderType';
import { HeaderSettings, ThemeSettings } from 'rev-portal/branding/BrandingSettings.Contract';
import { MediaMenuService } from 'rev-portal/navigation/mediaMenu/MediaMenu.Service';

import { LoginRedirectService } from 'rev-shared/security/LoginRedirect.Service';
import { UserContextService, IUserContextAccount } from 'rev-shared/security/UserContext.Service';
import { SecurityContextService } from 'rev-shared/security/SecurityContext.Service';
import { PORTAL_TEAM_STATE, PORTAL_MEDIA_STATE } from 'rev-shared/bootstrap/AppStateNames';

import { MobileSubMenu } from './MobileSubMenu.Constant';

import './mobile-menu.less';

@Component({
	selector: 'mobile-menu',
	templateUrl: './MobileMenu.Component.html'
})

export class MobileMenuComponent implements OnInit {
	@Input() private theme: BrandingHeaderType;
	@Input() private headerSettings: HeaderSettings;
	@Input() private themeSettings: ThemeSettings;
	@Input() public isAnalystAdmin: boolean;
	@Input() public showManageSubscriptions: boolean;
	@Input() public landingState: { state: string, params: any };

	private accountId: string;
	private subMenu: string;
	private ignoreDataFetch: boolean;
	public showHelpMenu: boolean;
	public readonly PORTAL_TEAM_STATE = PORTAL_TEAM_STATE;
	public readonly PORTAL_MEDIA_STATE = PORTAL_MEDIA_STATE;

	constructor(
		public $state: StateService,
		private LoginRedirectService: LoginRedirectService,
		private MediaMenuService: MediaMenuService,
		private SecurityContext: SecurityContextService,
		private UserContext: UserContextService,
	) { }

	public ngOnInit(): void {
		this.accountId = this.UserContext.getAccount().id;
		this.ignoreDataFetch = this.SecurityContext.checkAuthorization('distribution.authenticated');
	}

	public get isNettrixTheme(): boolean {
		return this.theme === BrandingHeaderType.Nettrix;
	}

	public get showVideoMenu(): boolean {
		return this.subMenu === MobileSubMenu.Video;
	}

	public loginRedirect(): void {
		this.LoginRedirectService.redirectToLogin();
	}

	public onVideoMenuClick(): void {
		this.subMenu = this.subMenu === MobileSubMenu.Video ? undefined : MobileSubMenu.Video;
	}

	public onToggleClick(): void {
		if (this.ignoreDataFetch) {
			return;
		}
		this.MediaMenuService.fetchAllData();
	}

	public get account(): IUserContextAccount {
		return this.UserContext.getAccount();
	}
}
