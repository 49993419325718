import { Component, EventEmitter, Output } from '@angular/core';

import { INotification } from 'rev-portal/notifications/Notification.Contract';
import { UserNotificationsService } from 'rev-portal/notifications/UserNotifications.Service';
import { IRules } from 'rev-shared/ui/css/CssRules.Contract';

import './NotificationsMenuContent.less';

@Component({
	selector: 'notifications-menu-content',
	templateUrl: './NotificationsMenuContent.Component.html'
})

export class NotificationsMenuContentComponent {
	@Output() public close: EventEmitter<void> = new EventEmitter<void>();
	public themeStyleOverrides: IRules;

	constructor(
		private UserNotifications: UserNotificationsService,
	) {
	}

	public get unreadNotifications(): INotification[] {
		return this.UserNotifications.unreadNotifications;
	}

	public get allNotifications(): INotification[] {
		return this.UserNotifications.notifications;
	}

	public markAsRead(notificationId: string): void {
		this.UserNotifications.markAsRead(notificationId);
		this.contentClosed();
	}

	public markAllAsRead(): void {
		if (this.allNotifications?.length === 0) { return; }
		this.UserNotifications.markAllAsRead();
		this.contentClosed();
	}

	public contentClosed(): void {
		this.close.emit();
	}
}
