import { Component, DoCheck, ElementRef, Input, Optional } from '@angular/core';
import { StateService } from '@uirouter/angular';

import { Subscription } from 'rxjs';

import { AppBootstrapService } from 'rev-shared/bootstrap/AppBootstrap.Service';
import { IRules } from 'rev-shared/ui/css/CssRules.Contract';
import { MediaFeaturesService } from 'rev-shared/media/MediaFeatures.Service';
import { LoginRedirectService } from 'rev-shared/security/LoginRedirect.Service';
import { ScheduledEventService } from 'rev-shared/scheduledEvents/ScheduledEvent.Service';
import { SecurityContextService } from 'rev-shared/security/SecurityContext.Service';
import { UserContextService, IUserContextUser, IUserContextAccount } from 'rev-shared/security/UserContext.Service';
import { PORTAL_DASHBOARD_STATE, PORTAL_MEDIA_SEARCH_STATE, PORTAL_VIDEO_PLAYBACK_STATE } from 'rev-shared/bootstrap/AppStateNames';

import { HeaderSettings, ThemeSettings } from 'rev-portal/branding/BrandingSettings.Contract';
import { MediaStateService, MediaViewMode } from 'rev-portal/media/MediaState.Service';
import { ThemeService } from 'rev-portal/branding/Theme.Service';

import { MainHeaderPositionService } from './MainHeaderPosition.Service';

import './main-header.less';

@Component({
	selector: 'main-header',
	templateUrl: './MainHeader.Component.html'
})
export class MainHeaderComponent implements DoCheck {
	@Input() public mainContent: ElementRef;

	private queryTxt: string;

	public themeServiceSub: Subscription;
	public themeStyleOverrides: IRules;
	public accountId: string;
	public event: any;
	public isAnalystAdmin: boolean;
	public showManageSubscriptions: boolean;
	public readonly PORTAL_DASHBOARD_STATE = PORTAL_DASHBOARD_STATE;
	public readonly PORTAL_VIDEO_PLAYBACK_STATE = PORTAL_VIDEO_PLAYBACK_STATE;

	public landingState: { state: string, params: any };
	public hideManageSubscriptions: boolean;

	constructor(
		AppBootstrapService: AppBootstrapService,
		private LoginRedirectService: LoginRedirectService,
		@Optional() private MediaStateService: MediaStateService,
		private SecurityContext: SecurityContextService,
		public MainHeaderPosition: MainHeaderPositionService,
		public MediaFeatures: MediaFeaturesService,
		public ScheduledEventService: ScheduledEventService,
		public ThemeService: ThemeService,
		public UserContext: UserContextService,
		public $state: StateService,
		public elementRef: ElementRef
	) {
		this.accountId = this.UserContext.getAccount().id;
		this.landingState = AppBootstrapService.getAppLandingState();
		this.hideManageSubscriptions = AppBootstrapService.appConfig?.isRevDistribution;
		MediaFeatures.getFeatures()
			.then(features => {
				this.showManageSubscriptions = (features.enableContentNotifications || features.enableSpark) && !this.hideManageSubscriptions;
			});
	}

	public ngDoCheck(): void {
		const isTransparencyActive =
			this.MainHeaderPosition.isTransparencySupported &&
			this.ThemeService.brandingSettings.headerSettings.useTransparentHeader;


		this.MainHeaderPosition.setTransparency(isTransparencyActive);
		this.isAnalystAdmin = !this.SecurityContext.hasAuthorizationKey((k => (/admin\.(?!reports)/).test(k)));
	}

	public loginRedirect(): void {
		this.LoginRedirectService.redirectToLogin();
	}

	public get currentTheme(): string {
		return this.headerSettings.brandingHeaderType;
	}

	public get guestPortalRestricted(): boolean {
		return this.isRegisteredGuest || !this.SecurityContext.checkAuthorization('guest');
	}

	public get headerSettings(): HeaderSettings {
		return this.ThemeService.brandingSettings.headerSettings;
	}

	public get isRegisteredGuest(): boolean {
		return this.UserContext.isRegisteredGuest();
	}

	public get isUserAuthenticated(): boolean {
		return this.UserContext.isUserAuthenticated();
	}

	public get themeSettings(): ThemeSettings {
		return this.ThemeService.brandingSettings.themeSettings;
	}

	public get user(): IUserContextUser {
		return this.UserContext.getUser();
	}

	public get account(): IUserContextAccount {
		return this.UserContext.getAccount();
	}

	public submitMediaSearch(): void {
		this.MediaStateService.setViewMode(MediaViewMode.TABLE);
		this.$state.go(PORTAL_MEDIA_SEARCH_STATE, { q: this.queryTxt }, { inherit: false, reload: true });
		this.queryTxt = '';
	}

	public skipNavigation(): void {
		const target = Array.from<HTMLElement>(this.mainContent.nativeElement
			.querySelectorAll('a:not([disabled]):not([hidden]), button:not([disabled]):not([hidden])'))
			.find(el => !findNearest(el, isHidden));

		target?.focus();
	}
}

function isHidden(el: HTMLElement): boolean {
	if(!(el instanceof Element)) {
		return false;
	}

	const style = window.getComputedStyle(el);
	return style.visibility === 'hidden' ||
		style.display === 'none';
}

function findNearest(el: HTMLElement, fn: (el: HTMLElement) => boolean): HTMLElement {
	if(fn(el)) {
		return el;
	}

	let parent = el.parentNode as HTMLElement;
	while(parent) {
		if(fn(parent)) {
			return parent;
		}
		parent = parent.parentNode as HTMLElement;
	}
}
