<div [ngClass]="styles.goLiveDropDown" *ngIf="webcast">
	<div class="margin-bottom-10">
		<span>{{ 'Event_CreateLiveEvent' | translate }}</span>
		<button type="button" class="btn-transparent util-underline float-right" [ngClass]="styles.templatesToggle" [attr.aria-label]="'Templates' | translate" (click)="toggleTemplate()">
			{{ 'Templates' | translate }}
		</button>
	</div>
	<form name="form" #form="ngForm" (submit)="submit()">
		<div [ngClass]="styles.goLiveForm">
			<vb-ui-form-group>
				<template-picker *ngIf="useTemplate" [additionalFilters]="templateFilters" [templatesService]="WebcastTemplatesService" (onTemplateSelected)="applyTemplate($event)" (closeMenu)="toggleTemplate()" uiControl></template-picker>
			</vb-ui-form-group>

			<vb-ui-form-group>
				<label for="goLiveEventTitle" #uiLabel>{{ 'Event_Title' | translate}}</label>
				<input name="goLiveEventTitle" id="goLiveEventTitle" [(ngModel)]="webcast.title" vbUiTextInput uiControl required/>
			</vb-ui-form-group>

			<vb-ui-form-group>
				<label for="goLiveEstimatedDurationHours" #uiLabel>{{ 'Event_EstimatedDuration' | translate }}</label>
				<vb-ui-select uiControl>
					<select #selectElement name="goLiveEstimatedDurationHours" id="goLiveEstimatedDurationHours" [(ngModel)]="webcast.estimatedDurationHours">
						<option *ngFor="let hour of hours" [ngValue]="hour">
							{{ (hour === 1 ? 'OneHour' : 'NumHours') | translate: { '0' : hour } }}
						</option>
					</select>
				</vb-ui-select>
			</vb-ui-form-group>

			<vb-ui-form-group>
				<label for="goLiveEventAccessControl" #uiLabel>{{ 'Event_Audience' | translate }}</label>
				<vb-ui-select uiControl>
					<select #selectElement name="goLiveEventAccessControl" id="goLiveEventAccessControl" [(ngModel)]="webcast.accessControl" (ngModelChange)="onAccessControlChange()">
						<option [value]="EventAccessControl.Public" *ngIf="publicListingVisible">
							{{ 'Event_Public' | translate }}
						</option>
						<option [value]="EventAccessControl.AllUsers">
							{{ 'Event_AllUsers' | translate }}
						</option>
						<option [value]="EventAccessControl.Private">
							{{ 'Event_Private' | translate }}
						</option>
					</select>
				</vb-ui-select>
			</vb-ui-form-group>

			<vb-ui-form-group *ngIf="webcast.accessControl === EventAccessControl.Public">
				<label for="goLivePublicEventPassword" #uiLabel>{{ 'Password_Optional' | translate }}</label>
				<input name="goLivePublicEventPassword" id="goLivePublicEventPassword" [(ngModel)]="webcast.password" vbUiTextInput uiControl/>
			</vb-ui-form-group>

			<vb-ui-form-group *ngFor="let field of webcast.customFields; trackBy: trackByIndex; let i = index;">
				<label [for]="'goLiveEventCustomField' + i" #uiLabel>{{ field.name }}</label>
				<ng-container [ngSwitch]="field.fieldType" uiControl>
					<input *ngSwitchCase="FieldType.Text" [name]="'goLiveEventCustomField' + i" [id]="'goLiveEventCustomField' + i" [(ngModel)]="field.value" vbUiTextInput [required]="field.required"/>
					<vb-ui-select *ngSwitchCase="FieldType.Select">
						<select #selectElement [name]="'goLiveEventCustomField' + i" [id]="'goLiveEventCustomField' + i" [(ngModel)]="field.value" [required]="field.required">
							<option [ngValue]="null"></option>
							<option *ngFor="let option of field.options" [ngValue]="option">
								{{ option }}
							</option>
						</select>
					</vb-ui-select>
				</ng-container>
			</vb-ui-form-group>
		</div>
		<div *ngIf="hoursAndCreditsInvalid" role="alert" class="margin-bottom-10" vbUiErrorMessage>
			<div *ngIf="insufficientViewingHours">
				{{ 'License_ViewingHours_NotAvailable' | translate }}
			</div>
			<div *ngIf="insufficientRevIQCredits">
				{{ 'Event_LiveEventSubtitles_InsufficientRevIQCredits' | translate }}
			</div>
		</div>
		<div class="float-right margin-top-15 margin-bottom-15">
			<button type="button" [ngClass]="styles.btnFocus" class="margin-right-15" vbBtnSecondary (click)="moreSettings()">{{ 'Event_MoreSettings' | translate }}</button>
			<button type="submit" [ngClass]="styles.btnFocus" vbBtnPrimary [disabled]="form.invalid || hoursAndCreditsInvalid">{{ 'Next' | translate }}</button>
		</div>
	</form>
</div>
