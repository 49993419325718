<ng-template [vbCssRules]="themeStyleOverrides">
</ng-template>
<header role="banner" [ngClass]="[
		'theme-' + currentTheme,
		$state.includes(PORTAL_VIDEO_PLAYBACK_STATE) ? 'no-border' : '',
		!$state.includes(PORTAL_DASHBOARD_STATE) ? 'theme-accent-border' : ''
	]" (vbOffsetHeightModel)="MainHeaderPosition.setHeight($event.offsetHeight)">
	<ng-container [ngSwitch]="currentTheme">
		<classic-header [mainContent]="mainContent" *ngSwitchCase="'Classic'"></classic-header>
		<nettrix-header [mainContent]="mainContent" *ngSwitchCase="'Nettrix'"></nettrix-header>
		<twotier-header [mainContent]="mainContent" *ngSwitchCase="'TwoTier'"></twotier-header>
	</ng-container>
</header>
