import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { isEqual } from 'underscore';

import { lastValueFrom } from 'rev-shared/rxjs/lastValueFrom';
import { AccessEntityType } from 'rev-shared/security/AccessEntityType';
import { DateParsersService } from 'rev-shared/date/DateParsers.Service';
import { FileWrapper } from 'rev-shared/ui/fileUpload/FileWrapper';
import { PushService } from 'rev-shared/push/PushService';
import { RecordingPolicy } from 'rev-shared/media/RecordingPolicy';
import { UserContextService } from 'rev-shared/security/UserContext.Service';
import { WebcastTemplatesService } from 'rev-shared/webcast/WebcastTemplates.Service';
import { formatIsoDate, formatTimespan, formatTimespanShort } from 'rev-shared/date/DateFormatters';

import { ISearchResult } from 'rev-portal/search/InsightSearchHelper.Service';
import { SearchConstants } from 'rev-portal/search/SearchConstants';
import { SearchService, IAccessEntityIdentifier } from 'rev-portal/search/Search.Service';

import { EditWebcastModel } from './EditWebcastModel';
import { EventAccessControl } from '../EventAccessControl';
import { IVideoSource } from './EditWebcast.Contract';
import { Poll } from '../polls/Poll';
import { PollsService } from '../polls/Polls.Service';
import { WebcastPresentationService } from '../presentations/WebcastPresentation.Service';
import { getReactionSettings, WebcastService } from '../webcast/Webcast.Service';
import { WebcastVideoSource } from '../webcast/WebcastVideoSource';
import { MediaFeaturesService } from 'rev-shared/media/MediaFeatures.Service';
import { SecurityContextService } from 'rev-shared/security/SecurityContext.Service';
import { UploadService } from 'rev-shared/media/Upload.Service';
import { VIEWER_ID_SETTING_HELPER } from 'rev-shared/viewerId/ViewerIdHelper';
import { ITemplateInfo } from 'rev-shared/webcast/WebcastTemplates.Contract';

import { WebcastType } from '../webcast/WebcastType';
import { ViewerIdPolicy } from 'rev-shared/viewerId/ViewerIdContract';

//move this to seprate file
export const AutomatedDatePastError = 'AutomatedWebcastDatePast';
export const InsufficientRevIQCredits = 'InsufficientRevIQCredits';
export const ShortcutNotAvailableError = 'WebcastShortcutNameAvailabilityNotValid';
export const TranscriptionIsNotEnabled = 'TranscriptionIsNotEnabled';
export const UnsupportedLiveSubtitlesSource = 'UnsupportedLiveSubtitlesSource';
export const UnsupportedLiveSubtitlesPresentationProfile = 'UnsupportedLiveSubtitlesPresentationProfile';
export const UploadFailedError = 'UploadFailed';
export const VideoSourceNotAvailableError = 'VideoSourceNotAvailable';
export const ViewingHoursNotAvailable = 'ViewingHoursNotAvailable';
export const RtmpPushUrlConflict = 'RtmpPushUrlConflict';
export const InsufficientViewingHours = 'InsufficientViewingHours';
export const VodDoesNotExist = 'VodDoesNotExist';
export const WebcastVideoSourceNotAllowed = 'WebcastVideoSourceNotAllowed';

export const LIVE_SUBTITLES_ERRORS = [
	InsufficientRevIQCredits,
	TranscriptionIsNotEnabled,
	UnsupportedLiveSubtitlesSource,
	UnsupportedLiveSubtitlesPresentationProfile,
	InsufficientViewingHours
];

const FORM_ERRORS: string[] = [
	AutomatedDatePastError,
	RtmpPushUrlConflict,
	ShortcutNotAvailableError,
	UploadFailedError,
	VideoSourceNotAvailableError,
	...LIVE_SUBTITLES_ERRORS
];

@Injectable({
	providedIn: 'root'
})
export class EditWebcastService {

	constructor(
		private http: HttpClient,
		private DateParsers: DateParsersService,
		private UploadService: UploadService,
		private MediaFeatures: MediaFeaturesService,
		private PollsService: PollsService,
		private PushService: PushService,
		private SearchService: SearchService,
		private SecurityContext: SecurityContextService,
		private UserContext: UserContextService,
		private WebcastService: WebcastService,
		private WebcastPresentationService: WebcastPresentationService,
		private WebcastTemplatesService: WebcastTemplatesService
	) {
	}

	public getVideoSourceSchedule(startDate: Date, endDate: Date): Promise<IVideoSource[]> {
		return lastValueFrom<any>(this.http.get('/scheduled-events/accounts/video-sources/schedule', {
			params: {
				startDate: formatIsoDate(startDate),
				endDate: formatIsoDate(endDate)
			}
		}))
			.then(({ videoSources }) => {
				videoSources.forEach(source => {
					source.displayName = [source.deviceName, source.name].filter(Boolean).join(' - ');

					source.events.forEach(e => {
						Object.assign(e, {
							startDate: this.DateParsers.parseUTCDate(e.startDate),
							endDate: this.DateParsers.parseUTCDate(e.endDate),
							eventAdminFirstName: e.eventAdminFirstName || '',
							eventAdminLastName: e.eventAdminLastName || ''
						});
						const preProdDurationMs = this.DateParsers.parseTimespan(e.preProductionDuration);
						if (preProdDurationMs > 0) {
							e.startDate = new Date(e.startDate.getTime() - preProdDurationMs);
						}
					});

					source.presentationProfiles.forEach(profile => {
						profile.videoSource = source;
					});
				});

				return videoSources;
			});
	}

	public queryEventAdmins(query: string): Promise<ISearchResult> {
		const accountId = this.UserContext.getAccount().id;
		return this.SearchService
			.queryAccessEntities({
				accountId,
				query,
				type: [SearchConstants.accessEntityTypes.user],
				sortField: SearchConstants.nameSortField,
				sortAscending: true,
				count: SearchConstants.accessEntityPageSize,
				accountPermissions: [
					SearchConstants.rights.createEvents
				],
				noScroll: true
			})
			.then(result => ({
				items: result.accessEntities,
				count: result.totalHits
			}));
	}

	public queryMediaContributors(query: string): Promise<ISearchResult> {
		const accountId = this.UserContext.getAccount().id;
		return this.SearchService
			.queryAccessEntities({
				accountId,
				query,
				type: [SearchConstants.accessEntityTypes.user],
				sortField: SearchConstants.nameSortField,
				sortAscending: true,
				count: SearchConstants.accessEntityPageSize,
				accountPermissions: [
					SearchConstants.rights.createEvents,
					SearchConstants.rights.createMedia
				],
				noScroll: true
			})
			.then(result => ({
				items: result.accessEntities,
				count: result.totalHits
			}));
	}

	public deleteWebcast(webcastId: string): Promise<void> {
		return this.PushService.dispatchCommand('scheduledEvents:DeleteWebcast', { webcastId });
	}

	public getWebcast(webcastId: string): Promise<EditWebcastModel> {
		return this.WebcastService.getWebcast(webcastId, true, true)
			.then(webcast => Promise.all([
				webcast,
				webcast.pollsEnabled && this.PollsService.getPolls(webcastId),
				this.WebcastService.getWebcastEmbeddedContent(webcastId, webcast.currentUser.canEdit)
			]))
			.then(([webcast, polls, e]) => new EditWebcastModel(
				{
					...webcast,
					embeddedContent: e?.embeddedContent,
					polls
				},
				this.SecurityContext,
				this.SearchService,
				this.UserContext
			));
	}

	public initNewWebcastModel(data: any): Promise<EditWebcastModel> {
		const webcast = this.createNewWebcast(data);
		return this.getDefaultTemplate()
			.then(template => {
				return this.applyTemplateToWebcast(template, webcast);
			});
	}

	public createNewWebcast(data: any): EditWebcastModel {
		return new EditWebcastModel(
			{
				eventAdminIds: [this.UserContext.getUser().id],
				presenterId: this.UserContext.getUser().id,
				presenter: { id: this.UserContext.getUser().id },
				recordingUploaderUserId: this.UserContext.getUser().id,
				...data,
				reactionsSettings: getReactionSettings(data.reactionsSettings)
			},
			this.SecurityContext,
			this.SearchService,
			this.UserContext
		);
	}

	public getDefaultTemplate(): Promise<ITemplateInfo> {
		return this.WebcastTemplatesService.getDefaultTemplate();
	}

	public applyTemplateToWebcast(template: ITemplateInfo, webcast: EditWebcastModel): Promise<EditWebcastModel> {
		if (template.metadata) {
			return webcast.applyTemplate(template).then(() => webcast);
		}

		webcast.customFields = template.customFields;
		return Promise.resolve(webcast);
	}

	public saveWebcast(webcast: EditWebcastModel): Promise<any> {
		const previewUpload = this.uploadPreviewThumbnailImage(webcast);
		const logoUpload = this.uploadLogoImage(webcast);
		const backgroundUpload = this.uploadBackgroundImage(webcast);
		return Promise.all([previewUpload, logoUpload, backgroundUpload]).then(([previewId, logoId]) => {
			webcast.previewThumbnailImageId = previewId;
			webcast.webcastBrandingSettings.logoImageId = logoId;
			this.cleanupFileWrapper(webcast);
			const command = this.buildWebcastCommand(webcast);
			return webcast.id ?
				this.PushService.dispatchCommand('scheduledEvents:SaveWebcastDetails', command, 'WebcastDetailsSaved') :
				this.PushService.dispatchCommand('scheduledEvents:ScheduleWebcast', command, 'WebcastScheduled');
		}).then(result => {
			const webcastId = result.message.webcastId;
			webcast.id = webcastId;
			return Promise.all([
				this.uploadPresentation(webcast),
				this.savePolls(webcast)
			]);
		}).catch(err => {
			console.error(err);
			if (err === UploadFailedError) {
				return Promise.reject(UploadFailedError);
			}

			if (!err.hasIssue) {
				return Promise.reject();
			}

			return Promise.reject(
				FORM_ERRORS.find(error => err.hasIssue(error))
			);
		});
	}

	public saveTemplateFromWebcastId(templateName: string, webcastId: string): Promise<void> {
		return this.getWebcast(webcastId)
			.then(webcast => this.saveWebcastTemplate(templateName, webcast));
	}

	public saveWebcastTemplate(templateName: string, webcast: EditWebcastModel): Promise<any> {
		return this.uploadLogoImage(webcast)
			.then(logoId => {
				webcast.webcastBrandingSettings.logoImageId = logoId;
				return this.PushService.dispatchCommand('scheduledEvents:AddWebcastTemplate', {
					name: templateName,
					polls: (webcast.polls || []).map(poll => this.PollsService.convertPollForCommand(poll)),
					...this.buildWebcastCommand(webcast, true)
				});
			});
	}

	public isFormErrorCode(err: string): boolean {
		return FORM_ERRORS.includes(err);
	}

	private buildWebcastCommand(webcast: EditWebcastModel, isTemplateCommand?: boolean): any {
		const features = this.MediaFeatures.accountFeatures;
		const isProducer = webcast.videoSource === WebcastVideoSource.PRODUCER;
		const embeddedContentEnabled = features.webcastEmbedContentSettings?.isAvailable && features.webcastEmbedContentSettings?.isEnabled && webcast.embeddedContent?.isEnabled;
		const bannersEnabled = features.webcastEngagementSettings?.allowSendLinkToAllAttendees && webcast.bannerDetails?.isEnabled;
		const watermarkText = features.viewerIdSettings?.viewerIdPolicy == ViewerIdPolicy.DISABLE ? null :
						   features.viewerIdSettings?.viewerIdPolicy == ViewerIdPolicy.REQUIRE ? features.viewerIdSettings.customInformation : webcast.viewerIdWatermarkText;

		return {
			webcastId: webcast.id,
			cloneWebcastId: webcast.cloneWebcastId,
			title: webcast.title,
			shortcutName: webcast.shortcutName,
			description: webcast.description,
			startDate: webcast.startDate,
			endDate: webcast.endDate,
			timezoneId: webcast.timezoneId,
			isFeatured: webcast.isFeatured,
			videoSource: webcast.videoSource,
			accessControl: webcast.accessControl,
			attendeeJoinMethod: webcast.attendeeJoinMethod,
			chatEnabled: features.webcastEngagementSettings.allowChat && webcast.chatEnabled,
			pollsEnabled: features.webcastEngagementSettings.allowPolls && webcast.pollsEnabled,
			userPollsResponse: webcast.pollsEnabled ? webcast.userPollsResponse : null,
			questionAndAnswerEnabled: webcast.questionAndAnswerEnabled,
			questionOption: webcast.questionOption,
			imageId: webcast.imageId,
			previewThumbnailImageId: webcast.previewThumbnailImageId,
			templateImageId: webcast.templateImageId,
			templatePreviewThumbnailImageId: webcast.templatePreviewThumbnailImageId,
			isBackgroundFill: webcast.isBackgroundFill,
			userIdSlideControl: webcast.moderators.find(({ id }) => webcast.userIdSlideControl === id) ? webcast.userIdSlideControl : null,
			tags: !isTemplateCommand || features.enableTags ? webcast.tags : [],
			categoryIds: !isTemplateCommand || features.enableCategories ? webcast.categories.map(({ categoryId }) => categoryId) : [],
			unlisted: webcast.unlisted,
			estimatedAttendees: webcast.estimatedAttendees || 0,
			lobbyTimeMinutes: webcast.lobbyTimeMinutes,

			eventAdminIds: webcast.eventAdmins.map(getId),
			moderatorIds: webcast.moderators.map(getId),
			presenterIds: isProducer ? webcast.presenters.map(getId) : null,
			externalPresenters: isProducer && features.enableExternalPresenters ? webcast.externalPresenters.map(p => p.data) : null,
			userIds: webcast.accessControl === EventAccessControl.Private ? filterByType(AccessEntityType.User, webcast.accessEntities) : null,
			groupIds: webcast.accessControl === EventAccessControl.Private ? filterByType(AccessEntityType.Group, webcast.accessEntities) : null,
			password: webcast.accessControl === EventAccessControl.Public ? webcast.password : null,
			registrationFields: webcast.accessControl === EventAccessControl.Public
				? webcast.registrationFields.map(f => ({
					id: f.id,
					includedByHost: !!f.includedByHost
				}))
				: null,
			customFieldValues: (webcast.customFields || []).map(({ id, value, fieldType }) => ({ id, value, fieldType })),

			presentationProfileId: webcast.videoSource === WebcastVideoSource.PRESENTATION ? webcast.presentationProfileId : null,
			webexTeam: webcast.videoSource === WebcastVideoSource.WEBEX ? webcast.webexTeam : null,
			vcSipAddress:
				webcast.videoSource === WebcastVideoSource.SIP_ADDRESS ? webcast.vcSipAddress :
					webcast.videoSource === WebcastVideoSource.PEXIP ? webcast.vcSipAddress :
						webcast.videoSource === WebcastVideoSource.WEBEX ? webcast.webexTeam.sipAddress :
							webcast.videoSource === WebcastVideoSource.ZOOM ? webcast.zoom.meetingPassword
								? `${webcast.zoom.meetingId}.${webcast.zoom.meetingPassword}@${this.MediaFeatures.accountFeatures.zoomSettings.sipAddressSuffix}`
								: `${webcast.zoom.meetingId}@${this.MediaFeatures.accountFeatures.zoomSettings.sipAddressSuffix}`
								: null,
			vcSipPin: webcast.isDtmfSource && webcast.videoSource !== WebcastVideoSource.ZOOM ? webcast.vcSipPin : null,
			vcDtmf: webcast.isDtmfSource ? webcast.vcDtmf : undefined,
			dtmfControlsEnabled: webcast.isDtmfSource ? webcast.vcDtmfControlsEnabled : undefined,
			vcMicrosoftTeamsMeetingUrl: webcast.videoSource === WebcastVideoSource.MSTEAMS ? webcast.vcMicrosoftTeamsMeetingUrl : null,
			automatedWebcast: webcast.videoSource !== WebcastVideoSource.WEBRTC_SINGLE_PRESENTER && webcast.automatedWebcast,
			autoplay: webcast.autoplay,
			closedCaptionsEnabled: webcast.videoSource === WebcastVideoSource.PRESENTATION && webcast.closedCaptionsEnabled,
			disableAutoRecording: webcast.disableAutoRecording,
			autoAssociateVod: webcast.autoAssociateVod,
			recordingUploaderUserId: this.MediaFeatures.accountFeatures.recordingPolicy === RecordingPolicy.DISABLE
				? null
				: (webcast.recordingUploaderUsers?.length > 0 ? webcast.recordingUploaderUsers[0].id : webcast.recordingUploaderUserId),
			redirectVod: webcast.redirectVod, presentationFileDownloadAllowed: !!(webcast.presentationFile &&
				webcast.presentationFile.name &&
				webcast.presentationFileDownloadAllowed),

			removePresentationFile: !webcast.presentationFile && !!webcast.originalPresentationFile,

			preProduction: webcast.preProduction && {
				duration: formatTimespan(webcast.preProduction.durationMs),
				groupIds: filterByType(AccessEntityType.Group, webcast.preProduction.attendees),
				userIds: filterByType(AccessEntityType.User, webcast.preProduction.attendees)
			},
			liveSubtitles: webcast.liveSubtitles?.isLiveSubtitlesEnabled ? webcast.liveSubtitles : undefined,
			hideShareUrl: webcast.hideShareUrl,
			enableCustomBranding: webcast.enableCustomBranding,
			webcastBrandingSettings: webcast.enableCustomBranding ? {
				headerColor: webcast.webcastBrandingSettings.headerColor,
				headerFontColor: webcast.webcastBrandingSettings.headerFontColor,
				primaryFontColor: webcast.webcastBrandingSettings.primaryFontColor,
				accentColor: webcast.webcastBrandingSettings.accentColor,
				accentFontColor: webcast.webcastBrandingSettings.accentFontColor,
				primaryColor: webcast.webcastBrandingSettings.primaryColor,
				logoImageId: webcast.webcastBrandingSettings.logoImageId
			} : {},
			rtmpSettings: webcast.rtmpSettings,
			emailToPreRegistrants: webcast.emailToPreRegistrants,
			viewerIdEnabled: features?.viewerIdSettings ? VIEWER_ID_SETTING_HELPER[features.viewerIdSettings.viewerIdPolicy](webcast.viewerIdEnabled) : undefined,
			viewerIdWatermarkText: watermarkText,
			vodId: webcast.videoSource === WebcastVideoSource.VOD ? webcast.vodInfo.id : null,
			vodInfo: webcast.videoSource === WebcastVideoSource.VOD ? {
				...webcast.vodInfo,
				duration: formatTimespanShort(webcast.vodInfo.durationMs, true, true)
			} : null,
			zoom: webcast.zoom,
			webcastType: webcast.webcastType ?? WebcastType.Rev,
			presenterId: webcast.videoSource === WebcastVideoSource.WEBRTC_SINGLE_PRESENTER ? webcast.presenter?.id : null,
			embeddedContent: {
				isEnabled: embeddedContentEnabled,
				contentLinks: embeddedContentEnabled ? webcast.embeddedContent.contentLinks : []
			},
			bannerDetails: {
				isEnabled: bannersEnabled,
				banners: bannersEnabled ? webcast.bannerDetails.banners : []
			},

			producerBgImages: webcast.videoSource !== WebcastVideoSource.PRODUCER ?
				null :
				webcast.producerBgImages
					.filter(img => !img.clone)
					.map(img => img.id),

			producerBgImagesToClone:  webcast.videoSource !== WebcastVideoSource.PRODUCER ?
				null :
				webcast.producerBgImages
					.filter(img => img.clone)
					.map(img => img.id),

			isCustomConsentEnabled: webcast.isCustomConsentEnabled,
			consentVerbiage: webcast.isCustomConsentEnabled ? webcast.consentVerbiage : null,
			reactionsSettings: {
				enabled: webcast.reactionsSettings.enabled,
				emojis: webcast.reactionsSettings.enabled
					? webcast.reactionsSettings.emojis.map(({ name, char }) => ({ name, character: char }))
					: []
			}
		};
	}

	private uploadPresentation(webcast: EditWebcastModel): Promise<void> | undefined {
		const file = webcast.presentationFile as FileWrapper;
		if (file && file.submit) {
			return this.WebcastPresentationService.uploadPresentationFile(file, webcast.id)
				.then(() => {
					webcast.originalPresentationFile = webcast.presentationFile = { name: file.name };
				});
		}
	}

	public uploadBackgroundImage(webcast: EditWebcastModel): Promise<void> {
		const file = webcast.backgroundImageFile;
		if (!file) {
			return Promise.resolve();
		}
		return this.PushService.dispatchCommand(
			webcast.id ? 'scheduledEvents:AddImageToWebcast' : 'media:AddImageToAccount',
			{
				accountId: this.UserContext.getAccount().id,
				webcastId: webcast.id,
				context: 'Webcast'
			},
			'ImageCreated'
		)
			.then(data => {
				const { imageId, uploadUri } = data.message;
				return this.UploadService.uploadFile(file, { id: imageId, uploadUri })
					.then(data => {
						const { thumbnailUri } = data.message;
						webcast.imageId = imageId;
						webcast.thumbnailUri = thumbnailUri;
					});
			});
	}

	public uploadProducerBgImage(webcast: EditWebcastModel, file: FileWrapper): Promise<void> {
		return this.UploadService.uploadImage('Webcast', file)
			.then(result => {
				const msg = result.message;
				webcast.producerBgImages = [
					...webcast.producerBgImages,
					{
						id: msg.imageId,
						original: msg.thumbnailUri
					}
				];
			});
	}

	public uploadLogoImage(webcast: EditWebcastModel): Promise<string> {
		const logoFile: FileWrapper = webcast.webcastBrandingSettings.logoFile;
		return logoFile
			? this.UploadService.uploadImage('Webcast', logoFile)
				.then(result => result.message.imageId)
			: Promise.resolve(webcast.webcastBrandingSettings.logoImageId);
	}

	public uploadPreviewThumbnailImage(webcast: EditWebcastModel): Promise<string> {
		const previewThumbnailFile: FileWrapper = webcast.previewThumbnailFile;
		return previewThumbnailFile
			? this.UploadService.uploadImage('Webcast', previewThumbnailFile)
				.then(result => result.message.imageId)
			: Promise.resolve(webcast.previewThumbnailImageId);
	}

	private savePolls(webcast: EditWebcastModel): Promise<void> {
		const polls = webcast.polls || [];
		const pollIds = new Set(polls.map(getId).filter(Boolean));

		const newPolls = polls.filter(poll => webcast.pollsEnabled && !poll.id);

		const updatedPolls = polls.filter(p => {
			const oldPoll = webcast.originalPolls[p.id];

			return webcast.pollsEnabled &&
				p.id &&
				(oldPoll as Poll).totalResponses === 0 &&
				!arePollsEqual(oldPoll, p);
		});

		const deletedPollIds =
			Object.keys(webcast.originalPolls)
				.filter(id => !webcast.pollsEnabled || !pollIds.has(id));

		return Promise.all([
			newPolls.length && this.PollsService.addWebcastPolls(webcast.id, newPolls),
			updatedPolls.length && this.PollsService.updateWebcastPolls(webcast.id, updatedPolls),
			deletedPollIds.length && this.PollsService.deleteWebcastPolls(webcast.id, deletedPollIds)
		])
			.then(([pollIds]) => {
				if (pollIds) {
					pollIds.forEach((id, index) => newPolls[index].id = id);
				}
				webcast.originalPolls = polls.reduce((pollsById, p) => ((pollsById[p.id] = copyPoll(p)), pollsById), {});
			});
	}

	public updateLinkedVideo(webcastId: string, videoId: string, redirectVod: boolean): Promise<void> {
		return this.PushService.dispatchCommand('scheduledEvents:UpdateLinkedVideo', { webcastId, videoId, redirectVod });
	}

	public validateEmbeddedLinkCode(code: string, webcastId?: string): Promise<any> {
		return lastValueFrom(webcastId ?
			this.http.post(`/scheduled-events/${webcastId}/embedded-contents/validation`, { code }) :
			this.http.post(`/scheduled-events/embedded-contents/validation`, { code })
		);
	}

	private cleanupFileWrapper(webcast: EditWebcastModel): void {
		if (webcast.previewThumbnailFile) {
			webcast.previewThumbnailFile = undefined;
		}

		if (webcast.webcastBrandingSettings?.logoFile) {
			webcast.webcastBrandingSettings.logoFile = undefined;
		}

		if (webcast.backgroundImageFile) {
			webcast.backgroundImageFile = undefined;
		}
	}
}

function filterByType(type: AccessEntityType, accessEntities: IAccessEntityIdentifier[]): string[] {
	if (!accessEntities || accessEntities.length === 0) {
		return;
	}
	return accessEntities
		.filter(e => e.type === type)
		.map(getId);
}

function getId({ id }: any): string {
	return id;
}

function arePollsEqual(a, b): boolean {
	return isEqual(pollData(a), pollData(b));
}

function pollData(p: any): any {
	return [
		p.question,
		p.answers,
		p.multipleChoice
	];
}

function copyPoll({ question, answers, multipleChoice, totalResponses }: any): any {
	return {
		totalResponses,
		multipleChoice,
		question,
		answers: answers.map(({ text }) => ({ text }))
	};
}
