<a target="_blank" rel="noopener noreferrer" class="dropdown theme-header-txt" [ngClass]="{
		'main-nav-btn line-height-1': !isTwoTierTheme
	}" [hidden]="hasCustomHelpLinks" [href]="helpUri" [attr.aria-label]="('Help' | translate) + ' | ' + ('OpensInNewTab' | translate) ">
	<span *ngIf="buttonMode" class="vb-icon vb-icon-help line-height-1"></span>
	<span *ngIf="!buttonMode">{{ 'Help' | translate }}</span>
</a>

<div class="align-right help-tray" dropdown vbDropdown #helpMenuDropDown="bs-dropdown" [hidden]="!hasCustomHelpLinks">
	<button type="button" class="dropdown-toggle" aria-controls="help-dropdown" dropdownToggle [ngClass]="{
			'btn main-nav-btn': buttonMode,
			'theme-header-txt': !buttonMode,
			'btn-transparent util-uppercase': isTwoTierTheme
		 }" [attr.aria-label]="('UI_Menu' | translate) + '-' + ('Help' | translate )">
		<span class="vb-icon vb-icon-help line-height-1" *ngIf="buttonMode">
		</span>
		<span class="theme-header-txt" *ngIf="!buttonMode">
			{{ 'Help' | translate }}
			<span vbCaretDirective></span>
		</span>
	</button>
	<div *dropdownMenu id="help-dropdown" role="menu" class="dropdown-menu dropdown-menu-right keep-border-radius lp-nav">
		<div class="list-group">
			<a role="menuitem" class="dropdown-item help-nav-link btn-icon-left" target="_blank" rel="noopener noreferrer" [href]="helpUri">
				<span class="glyphicons new_window_alt theme-header-txt"></span>{{ 'Help' | translate }}
			</a>
			<a *ngFor="let link of helpLinks.customHelpLinks" role="menuitem" class="dropdown-item help-nav-link btn-icon-left" [attr.aria-label]="link.label + ' | ' + ('OpensInNewTab' | translate)" [target]="link.uri?.startsWith('mailto') ? undefined : '_blank'" [href]="link.uri" rel="noopener noreferrer">
				<span class="glyphicons new_window_alt theme-header-txt"></span>{{link.label}}
			</a>
		</div>
	</div>
</div>
